import React, { useEffect } from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import NProgress from 'nprogress';
import { useRouter } from 'next/router';
import ContextProvider from 'common/context/index';
import { ToastContainer } from 'react-toastify';
import { Hydrate, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from 'common/config/queryClient';
// import { SessionProvider } from 'next-auth/react';
import { useWindowSize } from 'usehooks-ts';
import ReduxProvider from 'common/store/redux-provider';

import 'animate.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'yet-another-react-lightbox/styles.css';
import 'tippy.js/dist/tippy.css';
import '../common/styles/icons.css';
import '../common/styles/globals.css';

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const router = useRouter();
  const window = useWindowSize();
  const isMobile = window.width <= 768;

  useEffect(() => {
    const handleStart = (url: string) => {
      NProgress.start();
    };
    const handleStop = () => {
      NProgress.done();
    };
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <meta
          name='viewport'
          content='width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no'
        />
        <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
        <meta http-equiv='Content-Language' content='en' />
        <meta name='theme-color' content='#006FFD' />
        <meta name='msapplication-navbutton-color' content='#006FFD' />
        <meta name='msapplication-TileColor' content='#006FFD' />
        <meta name='apple-mobile-web-app-status-bar-style' content='#006FFD' />
        <meta name='apple-itunes-app' content='app-id=1672251268' />
    
      </Head>
      {/* <SessionProvider> */}
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <ReduxProvider>
            <ContextProvider>
              <ToastContainer
                rtl={false}
                position={isMobile ? 'top-right' : 'bottom-right'}
                autoClose={2000}
              />

              <div className='project-root'>
                <Component {...pageProps} />
              </div>
            </ContextProvider>
          </ReduxProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </Hydrate>
      </QueryClientProvider>

      {/* </SessionProvider> */}
    </>
  );
}

export default MyApp;

// ,modalCtx?.modalName && 'overflow-hidden'

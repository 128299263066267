import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  baseApiUrl,
  AccessTokenName,
  RefreshTokenName,
} from 'common/utils/constants';
import { isExpiredToken } from 'common/utils/methods';
import { error as toastError, warning } from 'common/utils/toast';
import { handleTokenInCookie, getTokenInCookie } from 'common/utils/methods';

const refreshAccessToken = async () => {
  const tokens = await getTokenInCookie();
  // const token = localStorage.getItem(RefreshTokenName);
  let response;
  let headers = {
    Authorization: `Bearer ${tokens[RefreshTokenName]}`,
  };

  try {
    response = await fetch(baseApiUrl + '/auth/refresh-token', {
      method: 'POST',
      headers,
    });
  } catch (err) {
    // Handle error
  }
  const data = await response?.json();
  const accessToken = data.data.accessToken;
  const refreshToken = data.data.refreshToken;
  handleTokenInCookie(accessToken, AccessTokenName, 'POST');
  handleTokenInCookie(refreshToken, RefreshTokenName, 'POST');

  return accessToken;
};

const sendCodeVerification = async (email: string) => {
  try {
    fetch(baseApiUrl + '/auth/resend-verification-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
  } catch (err) {
    // Handle error
  }
};

const onRequest = async (config: any): Promise<AxiosRequestConfig> => {
  const tokens = await getTokenInCookie();
  const accessToken = tokens[AccessTokenName];
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return {
    baseURL: baseApiUrl,
    ...config,
  };
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[request error] [${JSON.stringify(error)}]`)
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`)
  // console.log('ccc', response)
  return response;
};

const onResponseError = async (error: any) => {
  if (
    error.response &&
    error.response.status === 401 &&
    error.response?.config.url != '/auth/access-token-from-app-to-web-token'
  ) {
    const tokens = await getTokenInCookie();
    const refreshToken = tokens[RefreshTokenName];
    
    if (isExpiredToken(refreshToken)) {
      handleTokenInCookie('', AccessTokenName, 'DELETE');
      handleTokenInCookie('', RefreshTokenName, 'DELETE');
      window.location.replace('/signin');
    } else {
      try {
        // Refresh the access token
        const newAccessToken = await refreshAccessToken();

        //Update the request headers with the new access token
        const axiosError = error as any;
        axiosError.config.headers['Authorization'] = `Bearer ${newAccessToken}`;

        // Retry all requests in the queue with the new token
        new Promise<void>((resolve, reject) => {
          return axios
            .request(error.config)
            .then((response: any) => resolve(response))
            .catch((err) => reject(err));
        });

        // Retry the original request
        return axios(error.config);
      } catch (refreshError) {
        return null;
      }
    }
  } else if (
    error.response &&
    error.response.status === 403 &&
    error.response?.config.url == '/auth/sign-in'
  ) {
    const email = JSON.parse(error.response.config.data)['email'];
    sendCodeVerification(email);
    warning(error.response?.data?.message);
    window.location.replace('/verify');
  } else if (
    error.response &&
    (error.response.status === 400 || error.response.status === 404)
  ) {
    toastError(error.response?.data?.message);
  }
  return Promise.reject(error);
};
axios.interceptors.request.use(onRequest, onRequestError);
axios.interceptors.response.use(onResponse, onResponseError);

export const http = axios;

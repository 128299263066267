var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"lA7dPyKn2aekjUg3687Xt"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
const sentryDSN = process.env.NEXT_PUBLIC_SENTRY_DNS;

if (Boolean(sentryDSN)) {
  Sentry.init({
    dsn: sentryDSN,
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  });
}
